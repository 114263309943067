import React from "react"

import { withAccountAddresses } from "./withAccountAddresses"
import { AccountHeader } from "../Header/AccountHeader"
import { AccountAddressesForm } from "./Form/AccountAddressesForm"
import { AccountAddressesList } from "./List/AccountAddressesList"
import { StyledContainer } from "../../Styled/Container"
import { H3 } from "../../Styled/Text"
import { Page } from "./styledAccountAddresses"

export const AccountAddresses = withAccountAddresses(
  ({
    address,
    addresses,
    errors,
    handleChange,
    handleDefault,
    handleDelete,
    handleSubmit,
    id,
    loading,
    location,
    page,
    register,
    saving,
    template,
  }): JSX.Element => (
    <>
      <AccountHeader location={location} page={page} template={template} />
      <Page>
        <StyledContainer width={`md`}>
          <H3 align={`center`} withSpacing={address?.action ? `xl` : `sm`}>
            {id ? (address?.id ? page?.additionalEdit : page?.additionalAdd) : page?.title}
          </H3>
          {id ? (
            <AccountAddressesForm
              address={address}
              addresses={addresses}
              errors={errors}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              loading={loading}
              page={page}
              register={register}
            />
          ) : (
            <AccountAddressesList
              addresses={addresses}
              handleDefault={handleDefault}
              handleDelete={handleDelete}
              loading={loading}
              page={page}
              saving={saving}
            />
          )}
        </StyledContainer>
      </Page>
    </>
  )
)
