import React, { memo, useEffect, useMemo, useRef } from "react"

import { useApp } from "../../../../hooks/useApp"
import { useCore } from "../../../../hooks/useCore"
import { useRoutes } from "../../../../hooks/useRoutes"

export const withAccountAddressesList = Component =>
  memo(({ name = "AccountAddressesList", addresses, handleDefault, handleDelete, loading, page, saving }: any) => {
    const { activeAddress, setActiveAddress } = useApp()
    const {
      helpers: { isBrowser },
    } = useCore()
    const { routeResolver } = useRoutes()
    const form = useRef()

    const url = `${routeResolver({ type: `addresses` })}/new`

    // intentionally only run at first render
    useEffect(() => {
      if (isBrowser && activeAddress && form?.current) {
        setTimeout(() => {
          window.scrollTo({ top: form.current?.offsetTop - 100, behavior: "smooth" })
          setActiveAddress(null)
        }, 100)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    Component.displayName = name
    return useMemo(
      () =>
        !loading || saving || addresses?.length > 0 ? (
          <Component
            active={activeAddress}
            addresses={addresses}
            form={form}
            handleDefault={handleDefault}
            handleDelete={handleDelete}
            loading={saving}
            page={page}
            url={url}
          />
        ) : null,
      [activeAddress, addresses, form, handleDefault, handleDelete, loading, page, saving, url]
    )
  })
