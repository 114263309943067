import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withAccountAddressesList } from "./withAccountAddressesList"
import { Icon } from "../../../Icon/Icon"
import { FormCheckbox } from "../../../Form/Checkbox/FormCheckbox"
import { StyledLink } from "../../../Styled/Button"
import { StyledColumn } from "../../../Styled/Column"
import { StyledRow } from "../../../Styled/Row"
import { H5, P } from "../../../Styled/Text"
import { Address, Button, AddButton } from "./styledAccountAddressesList"

export const AccountAddressesList = withAccountAddressesList(
  ({ active, addresses, form, handleDefault, handleDelete, loading, page, url }): JSX.Element => (
    <>
      {addresses?.length > 0 ? (
        <>
          <StyledRow justify={`center`} withSpacing>
            <AddButton as={GatsbyLink} colour={`purple`} size={`secondary`} title={page?.additionalAdd} to={url}>
              {page?.additionalAdd}
            </AddButton>
          </StyledRow>
          {addresses?.map(
            ({ address1, address2, city, company, country, default: primary, firstName, id, legacyId, lastName, phone, province, url, zip }) => (
              <Address key={id?.toString()} ref={active === legacyId ? form : null}>
                <StyledRow gutter withSpacing={`sm`}>
                  <StyledColumn gutter width={`grow`}>
                    {(firstName || lastName) && <P large>{`${firstName || ``}${firstName && lastName ? ` ` : ``}${lastName || ``}`}</P>}
                    {company && <P large>{company}</P>}
                    {address1 && <P large>{address1}</P>}
                    {address2 && <P large>{address2}</P>}
                    {city && <P large>{city}</P>}
                    {province && <P large>{province}</P>}
                    {(zip || country) && <P large>{`${zip || ``}${zip && country ? `, ` : ``}${country || ``}`}</P>}
                    {phone && <P large>{phone}</P>}
                  </StyledColumn>
                  <StyledColumn gutter width={`no-shrink`}>
                    <StyledLink as={GatsbyLink} colour={`purple-dark`} disabled={loading} title={page?.additionalEdit} to={url} withSpacing={`sm`}>
                      <Icon name={`edit`} width={18} />
                    </StyledLink>
                    <StyledLink colour={`purple-dark`} onClick={() => handleDelete(id)} disabled={loading} title={page?.additionalRemove}>
                      <Icon name={`delete`} width={18} />
                    </StyledLink>
                  </StyledColumn>
                </StyledRow>
                {addresses?.length > 1 && (
                  <FormCheckbox
                    name={`default`}
                    checked={primary || false}
                    disabled={loading}
                    readOnly={primary}
                    full
                    onChange={() => handleDefault(id)}
                    placeholder={page?.additionalPrimary}
                  />
                )}
              </Address>
            )
          )}
        </>
      ) : (
        <>
          <H5 align={`center`} colour={`grey-darker`} large withSpacing={`lg`}>
            {page?.additionalEmpty}
          </H5>
          <StyledRow justify={`center`}>
            <Button as={GatsbyLink} colour={`purple`} size={`primary`} title={page?.additionalAdd} to={url}>
              {page?.additionalAdd}
            </Button>
          </StyledRow>
        </>
      )}
    </>
  )
)
