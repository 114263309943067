import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../../hoc/withAuthentication"
import { AccountAddresses as Page } from "../../components/Account/Addresses/AccountAddresses"

export const query = graphql`
  query {
    page: sanityPageAccountAddresses {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      additionalAdd
      additionalEdit
      additionalCreate
      additionalSave
      additionalCancel
      additionalRemove
      additionalEmpty
      additionalPrimary
      additionalCompany
      additionalAddress1
      additionalAddress2
      additionalCity
      additionalPostal
      additionalState
      additionalCountry
      additionalPhone
    }
    register: sanityPageAccountRegister {
      additionalFirstName
      additionalLastName
    }
    template: sanityTemplateAccount {
      bannerTitle
      bannerText
      bannerBackground
      bannerImage: _rawBannerImage(resolveReferences: { maxDepth: 2 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
    }
  }
`

const Component = withAuthentication(({ data, ...props }): JSX.Element => <Page {...props} {...data} />)

export default Component
