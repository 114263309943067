import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { withAccountAddressesForm } from "./withAccountAddressesForm"
import { FormCheckbox } from "../../../Form/Checkbox/FormCheckbox"
import { FormInput } from "../../../Form/Input/FormInput"
import { FormSelect } from "../../../Form/Select/FormSelect"
import { FormTel } from "../../../Form/Tel/FormTel"
import { StyledColumn } from "../../../Styled/Column"
import { StyledRow } from "../../../Styled/Row"
import { Form, Button, Link } from "./styledAccountAddressesForm"

export const AccountAddressesForm = withAccountAddressesForm(
  ({ address, addresses, errors, form, handleChange, handleSubmit, loading, locales, region, url }): JSX.Element => (
    <Form onSubmit={handleSubmit} ref={form}>
      <FormInput
        autoFocus
        name={`firstName`}
        type={`text`}
        full
        onChange={handleChange}
        placeholder={locales?.additionalFirstName}
        required
        value={address?.firstName || ""}
        withSpacing={`xs`}
      />
      <FormInput
        name={`lastName`}
        type={`text`}
        full
        onChange={handleChange}
        placeholder={locales?.additionalLastName}
        required
        value={address?.lastName || ""}
        withSpacing={`xs`}
      />
      <FormInput
        name={`company`}
        type={`text`}
        full
        onChange={handleChange}
        placeholder={locales?.additionalCompany}
        value={address?.company || ""}
        withSpacing={`xs`}
      />
      <FormInput
        name={`address1`}
        type={`text`}
        full
        onChange={handleChange}
        placeholder={locales?.additionalAddress1}
        required
        value={address?.address1 || ""}
        withSpacing={`xs`}
      />
      <FormInput
        name={`city`}
        type={`text`}
        full
        onChange={handleChange}
        placeholder={locales?.additionalCity}
        required
        value={address?.city || ""}
        withSpacing={`xs`}
      />
      <FormSelect
        name={`country`}
        options={region?.country}
        full
        onChange={handleChange}
        placeholder={locales?.additionalCountry}
        required
        value={address?.country}
        withSpacing={`xs`}
      />
      <StyledRow gutter withWrap>
        <StyledColumn gutter width={`md:1/2`}>
          <FormInput
            name={`zip`}
            type={`text`}
            full
            onChange={handleChange}
            placeholder={locales?.additionalPostal}
            required
            value={address?.zip || ""}
            withSpacing={`xs`}
          />
        </StyledColumn>
        <StyledColumn gutter width={`md:1/2`}>
          <FormSelect
            name={`province`}
            options={region?.province}
            full
            onChange={handleChange}
            placeholder={locales?.additionalState}
            required
            value={address?.province || ""}
            withSpacing={`xs`}
          />
        </StyledColumn>
      </StyledRow>
      <FormTel
        name={`phone`}
        country={region?.code}
        errors={errors}
        full
        onChange={handleChange}
        placeholder={locales?.additionalPhone}
        value={address?.phone || ""}
        withSpacing={`md`}
      />
      {((address?.id && addresses?.length > 1) || (!address?.id && addresses?.length > 0)) && (
        <FormCheckbox
          name={`default`}
          checked={address?.default || false}
          full
          onChange={handleChange}
          placeholder={locales?.additionalPrimary}
          withSpacing={`lg`}
        />
      )}
      <Button
        type={`submit`}
        colour={`purple`}
        size={`primary`}
        disabled={loading}
        title={address?.id ? locales?.additionalSave : locales?.additionalCreate}
        withSpacing={`xs`}
      >
        {address?.id ? locales?.additionalSave : locales?.additionalCreate}
      </Button>
      <Link as={GatsbyLink} disabled={loading} size={`large`} title={locales?.additionalCancel} to={url}>
        {locales?.additionalCancel}
      </Link>
    </Form>
  )
)
