import React from "react"

import { withFormCheckbox } from "./withFormCheckbox"
import { Icon } from "../../Icon/Icon"
import { StyledErrors } from "../../Styled/Form"
import { StyledCheckbox, StyledCheckboxLabel, StyledCheckboxContent, StyledCheckboxWrapper, StyledSrLabel } from "../../Styled/Form"

export const FormCheckbox = withFormCheckbox(({ disabled, errors, field, full, id, label, placeholder, readOnly, withSpacing }) => (
  <StyledCheckboxWrapper className={`group`} disabled={disabled} readOnly={readOnly} full={full} withSpacing={!(errors?.length > 0) && withSpacing}>
    <StyledSrLabel htmlFor={id}>{placeholder}</StyledSrLabel>
    <StyledCheckbox id={id} name={label} title={placeholder} type={`checkbox`} {...field} />
    <div>
      <div>
        <Icon name={`check`} width={16} />
      </div>
    </div>
    <StyledCheckboxContent>
      <StyledCheckboxLabel large withSpacing={errors?.length > 0 && `sm`}>
        {placeholder}
      </StyledCheckboxLabel>
      {errors?.length > 0 && (
        <>
          {errors?.map(({ code, message }) => (
            <StyledErrors key={code?.toString()} withSpacing={withSpacing}>
              {message?.toString()}
            </StyledErrors>
          ))}
        </>
      )}
    </StyledCheckboxContent>
  </StyledCheckboxWrapper>
))
