import React, { memo, useCallback, useEffect, useMemo } from "react"

import { useCore } from "../../../hooks/useCore"
import { useCustomerAddress } from "../../../hooks/useCustomer"

export const withAccountAddresses = Component =>
  memo(({ name = "AccountAddresses", location, page, register, template }: any) => {
    const {
      helpers: { decodeShopifyId },
    } = useCore()
    const { addresses, address, setAddress, createAddress, defaultAddress, updateAddress, deleteAddress, initialData, loading, saving, errors } =
      useCustomerAddress()

    const { id } = useMemo(() => {
      const parts = location?.pathname?.split(`/`) || []
      const id = parts?.length > 3 ? parts[parts?.length - 1] : null

      return { id }
    }, [location])

    const handleSubmit = useCallback(
      async event => {
        event.preventDefault()

        if (address.action === "update") {
          await updateAddress(address?.id, address)
        } else {
          await createAddress(address)
        }
      },
      [address, createAddress, updateAddress]
    )

    const handleChange = useCallback(
      ({ target: { type, name, value, checked } }) =>
        setAddress(prevState => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        })),
      [setAddress]
    )

    const handleDelete = useCallback(async id => await deleteAddress(id), [deleteAddress])

    const handleDefault = useCallback(async id => await defaultAddress(id), [defaultAddress])

    useEffect(() => {
      const item = addresses?.find(address => id === decodeShopifyId(address?.id, `Address`)?.split(`?`)?.[0])

      if (item?.id && item?.id !== address?.id) {
        setAddress({ ...item, action: `update` })
      } else if (!item?.id && address?.id) {
        setAddress({ ...initialData })
      }
    }, [address, addresses, decodeShopifyId, id, initialData, setAddress])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          address={address}
          addresses={addresses}
          errors={errors}
          handleChange={handleChange}
          handleDefault={handleDefault}
          handleDelete={handleDelete}
          handleSubmit={handleSubmit}
          id={id}
          loading={loading}
          location={location}
          page={page}
          register={register}
          saving={saving}
          template={template}
        />
      ),
      [address, addresses, errors, handleChange, handleDefault, handleDelete, handleSubmit, id, loading, location, page, register, saving, template]
    )
  })
