import React, { memo, useMemo } from "react"

export const withFormCheckbox = Component =>
  memo(
    ({
      name = "FormCheckbox",
      disabled = false,
      errors = null,
      full = false,
      id: formId = null,
      name: label,
      placeholder,
      readOnly = false,
      withSpacing = null,
      ...field
    }: any) => {
      const id = formId || `${label}_${Math.random().toString(36).substr(2, 9)}`

      Component.displayName = name
      return useMemo(
        () => (
          <Component
            disabled={disabled}
            errors={errors}
            field={field}
            full={full}
            id={id}
            label={label}
            placeholder={placeholder}
            readOnly={readOnly}
            withSpacing={withSpacing}
          />
        ),
        [disabled, errors, field, full, id, label, placeholder, readOnly, withSpacing]
      )
    }
  )
