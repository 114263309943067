import React, { memo, useEffect, useMemo, useRef } from "react"
import { CountryRegionData } from "react-country-region-selector"

import { useCore } from "../../../../hooks/useCore"
import { useRoutes } from "../../../../hooks/useRoutes"

export const withAccountAddressesForm = Component =>
  memo(({ name = "AccountAddressesForm", address, addresses: items, errors, handleChange, handleSubmit, loading, page, register }: any) => {
    const {
      helpers: { isBrowser },
    } = useCore()
    const { routeResolver } = useRoutes()
    const form = useRef()

    const url = routeResolver({ item: { id: `new` }, type: `addresses` })
    const locales = useMemo(
      () => ({
        ...page,
        ...register,
      }),
      [page, register]
    )

    const region = useMemo(
      () => ({
        country: CountryRegionData.map(([country]) => ({ value: country })),
        code: address?.country
          ? CountryRegionData.find(([region]) => region?.toLowerCase()?.trim() === address?.country?.toLowerCase()?.trim())?.[1]
          : null,
        province: address?.country
          ? CountryRegionData.find(([region]) => region?.toLowerCase()?.trim() === address?.country?.toLowerCase()?.trim())?.[2]
              ?.split(`|`)
              ?.map(region => ({ value: region?.split(`~`)?.[0] }))
          : [],
      }),
      [address]
    )

    // intentionally only run at first render
    useEffect(() => {
      isBrowser && form?.current ? setTimeout(() => window.scrollTo({ top: form.current?.offsetTop - 125, behavior: "smooth" }), 100) : null
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          address={address}
          addresses={items}
          errors={errors}
          form={form}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          loading={loading}
          locales={locales}
          region={region}
          url={url}
        />
      ),
      [address, items, errors, form, handleChange, handleSubmit, loading, locales, region, url]
    )
  })
